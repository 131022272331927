<template>
  <div
    id="scroll-1"
    class="min-h-page-login w-full bg-color-primary p-0 relative justify-center items-center flex overflow-hidden"
  >
    <div class="decor-top"></div>
    <div class="decor-bottom"></div>
    <transition name="fade" mode="out-in">
      <div
        v-if="isLoading"
        class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11 flex flex-col items-center justify-center"
      >
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="399.389px"
          height="399.389px"
          viewBox="0 0 399.389 399.389"
          style="enable-background:new 0 0 399.389 399.389;"
          xml:space="preserve"
          class="animate-spin w-16 h-16 fill-[#00446F] m-auto"
        >
          <g>
            <path
              d="M340.896,58.49C303.18,20.773,253.031,0.001,199.693,0.001c-53.34,0-103.487,20.771-141.204,58.489
              C20.772,96.208,0,146.355,0,199.694c0,53.34,20.772,103.489,58.49,141.206c37.717,37.717,87.864,58.488,141.204,58.488
              c53.339,0,103.486-20.771,141.205-58.488c37.717-37.717,58.49-87.865,58.49-141.206C399.387,146.355,378.613,96.208,340.896,58.49z
              M321.93,199.694c0,67.401-54.836,122.236-122.237,122.236S77.457,267.096,77.457,199.694S132.292,77.458,199.693,77.458
              S321.93,132.293,321.93,199.694z M328.061,71.327c3.352,3.353,6.553,6.817,9.607,10.379l-29.262,29.262
              c-25.766-31.512-64.928-51.664-108.713-51.664c-4.593,0-9.134,0.229-13.615,0.662V18.655c4.508-0.332,9.049-0.5,13.615-0.5
              C248.184,18.155,293.771,37.038,328.061,71.327z"
            />
          </g>
        </svg>
        <p
          class="text-center font-solusi text-xl font-semibold mt-3 text-[#00446F]"
        >
          Please Wait...
        </p>
      </div>
      <div v-else>
        <transition name="fade" mode="out-in" appear>
          <div
            v-if="isExpired"
            class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11"
          >
            <img
              src="/assets/images/modal/illustration-failed.svg"
              class="w-52 m-auto"
              alt=""
            />
            <p
              class="pt-6 pb-2 text-[#212121] text-2xl text-center font-desain font-semibold"
            >
              Link Kadaluwarsa!
            </p>
            <p
              class="pb-6 text-[#212121] text-base text-center font-solusi font-medium"
            >
              Sayang sekali link kamu kadaluwarsa, silahkan request reset
              password kembali untuk mendapatkan link terbaru.
            </p>
            <router-link
              to="/forgot-password"
              class="w-full mx-auto px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6 bg-[#00446F] text-white"
            >
              Request Reset Password
            </router-link>
          </div>
          <div
            v-else
            class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11"
          >
            <!-- <img src="/assets/images/mentor/logo-primary.svg" class="m-auto" /> -->
            <p
              class="pt-6 pb-2 text-[#212121] text-2xl text-center font-desain font-semibold"
            >
              Buat Kata Sandi Baru
            </p>
            <p
              class="pb-6 text-[#212121] text-sm text-left font-solusi font-medium"
            >
              Masukkan kata sandi baru Anda, pastikan Anda mengingat kata sandi
              baru Anda ya.
            </p>
            <form @submit.prevent="resetPassword">
              <div class="mb-4">
                <div
                  class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
                  :class="errors.password ? 'border-error' : 'border-black-80 '"
                >
                  <div class="p-2 z-10">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      class="w-6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C9.0992 2 6.74286 4.45452 6.74286 7.47619V10.0952H5.6C4.7259 10.0952 4 10.8514 4 11.7619V20.3333C4 21.2439 4.7259 22 5.6 22H18.4C19.2741 22 20 21.2439 20 20.3333V11.7619C20 10.8514 19.2741 10.0952 18.4 10.0952H17.2571V7.47619C17.2571 4.45452 14.9008 2 12 2ZM12 3.42857C14.1643 3.42857 15.8857 5.22171 15.8857 7.47619V10.0952H8.11429V7.47619C8.11429 5.22171 9.8357 3.42857 12 3.42857ZM5.6 11.5238H18.4C18.5384 11.5238 18.6286 11.6177 18.6286 11.7619V20.3333C18.6286 20.4775 18.5384 20.5714 18.4 20.5714H5.6C5.46161 20.5714 5.37143 20.4775 5.37143 20.3333V11.7619C5.37143 11.6177 5.46161 11.5238 5.6 11.5238ZM12 12.9524C10.8723 12.9524 9.94286 13.9206 9.94286 15.0952C9.94286 15.5826 10.1339 16.0058 10.4 16.3676V17.4762C10.4 18.3867 11.1259 19.1429 12 19.1429C12.8741 19.1429 13.6 18.3867 13.6 17.4762V16.3676C13.8661 16.0058 14.0571 15.5826 14.0571 15.0952C14.0571 13.9206 13.1277 12.9524 12 12.9524ZM12 14.381C12.3866 14.381 12.6857 14.6925 12.6857 15.0952C12.6857 15.311 12.5991 15.4915 12.4571 15.6235C12.3107 15.7593 12.2277 15.9546 12.2286 16.1592V17.4762C12.2286 17.6203 12.1384 17.7143 12 17.7143C11.8616 17.7143 11.7714 17.6203 11.7714 17.4762V16.1592C11.7723 15.9546 11.6893 15.7593 11.5429 15.6235C11.4009 15.4915 11.3143 15.311 11.3143 15.0952C11.3143 14.6925 11.6134 14.381 12 14.381Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                  <input
                    :type="[passwordStatus ? 'text' : 'password']"
                    name="password"
                    placeholder=" "
                    required
                    v-focus
                    v-model="resetForm.password"
                    @input="checkPassword"
                    autocomplete="on"
                    class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                    :class="
                      errors.password
                        ? 'focus:border-[#DE1306]'
                        : 'focus:border-[#212121]'
                    "
                  />
                  <div class="p-2 z-10 cursor-pointer" @click="togglePassword">
                    <svg
                      v-if="passwordStatus"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                  <label
                    for="password"
                    class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                    :class="
                      errors.password ? 'text-[#DE1306]' : 'text-[#212121]'
                    "
                  >
                    Kata Sandi Baru
                  </label>
                </div>
                <p
                  v-if="errors.password"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.password }}
                </p>
              </div>

              <div class="mb-6">
                <div
                  class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
                  :class="
                    errors.passwordConfirmation
                      ? 'border-error'
                      : 'border-black-80 '
                  "
                >
                  <div class="p-2 z-10">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      class="w-6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 2C9.0992 2 6.74286 4.45452 6.74286 7.47619V10.0952H5.6C4.7259 10.0952 4 10.8514 4 11.7619V20.3333C4 21.2439 4.7259 22 5.6 22H18.4C19.2741 22 20 21.2439 20 20.3333V11.7619C20 10.8514 19.2741 10.0952 18.4 10.0952H17.2571V7.47619C17.2571 4.45452 14.9008 2 12 2ZM12 3.42857C14.1643 3.42857 15.8857 5.22171 15.8857 7.47619V10.0952H8.11429V7.47619C8.11429 5.22171 9.8357 3.42857 12 3.42857ZM5.6 11.5238H18.4C18.5384 11.5238 18.6286 11.6177 18.6286 11.7619V20.3333C18.6286 20.4775 18.5384 20.5714 18.4 20.5714H5.6C5.46161 20.5714 5.37143 20.4775 5.37143 20.3333V11.7619C5.37143 11.6177 5.46161 11.5238 5.6 11.5238ZM12 12.9524C10.8723 12.9524 9.94286 13.9206 9.94286 15.0952C9.94286 15.5826 10.1339 16.0058 10.4 16.3676V17.4762C10.4 18.3867 11.1259 19.1429 12 19.1429C12.8741 19.1429 13.6 18.3867 13.6 17.4762V16.3676C13.8661 16.0058 14.0571 15.5826 14.0571 15.0952C14.0571 13.9206 13.1277 12.9524 12 12.9524ZM12 14.381C12.3866 14.381 12.6857 14.6925 12.6857 15.0952C12.6857 15.311 12.5991 15.4915 12.4571 15.6235C12.3107 15.7593 12.2277 15.9546 12.2286 16.1592V17.4762C12.2286 17.6203 12.1384 17.7143 12 17.7143C11.8616 17.7143 11.7714 17.6203 11.7714 17.4762V16.1592C11.7723 15.9546 11.6893 15.7593 11.5429 15.6235C11.4009 15.4915 11.3143 15.311 11.3143 15.0952C11.3143 14.6925 11.6134 14.381 12 14.381Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                  <input
                    :type="[passwordConfirmStatus ? 'text' : 'password']"
                    name="password-confirmation"
                    placeholder=" "
                    required
                    autocomplete="on"
                    @input="checkPassword"
                    v-model="resetForm.passwordConfirmation"
                    class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                    :class="
                      errors.passwordConfirmation
                        ? 'focus:border-[#DE1306]'
                        : 'focus:border-[#212121]'
                    "
                  />
                  <div
                    class="p-2 z-10 cursor-pointer"
                    @click="togglePasswordConfirm"
                  >
                    <svg
                      v-if="passwordConfirmStatus"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.4412 14.5821C16.9013 13.5544 18.0356 12.1014 18.634 10.3638C18.6783 10.2358 18.6783 10.0966 18.634 9.96937C17.4245 6.45558 14.0222 4.09512 10.1668 4.09512C8.66165 4.09512 7.22835 4.45876 5.96514 5.10619L2.70351 1.84456C2.46635 1.6074 2.08217 1.6074 1.84501 1.84456C1.60785 2.08172 1.60785 2.4659 1.84501 2.70306L4.89253 5.75058C3.43246 6.77825 2.29812 8.23126 1.69971 9.96882C1.65544 10.0969 1.65544 10.236 1.69971 10.3633C3.44911 15.4471 9.52341 17.7088 14.3678 15.2257L17.6294 18.4873C17.8666 18.7245 18.2508 18.7245 18.4879 18.4873C18.7251 18.2502 18.7251 17.866 18.4879 17.6288L15.4412 14.5821ZM10.167 5.30939C13.4311 5.30939 16.3157 7.25248 17.4143 10.1663C16.8602 11.6359 15.8507 12.8581 14.5686 13.7086L13.133 12.2731C13.5575 11.6778 13.8097 10.9521 13.8097 10.1664C13.8097 8.15765 12.1757 6.52366 10.167 6.52366C9.38122 6.52366 8.65552 6.77584 8.05949 7.20035L6.8745 6.01536C7.88084 5.56161 8.9986 5.30943 10.1671 5.30943L10.167 5.30939ZM8.08236 8.94015L11.393 12.2508C11.0317 12.4642 10.6159 12.5946 10.1669 12.5946C8.82773 12.5946 7.73841 11.5053 7.73841 10.1662C7.73841 9.71713 7.86884 9.30134 8.08228 8.94007L8.08236 8.94015ZM8.94086 8.08164C9.30213 7.8682 9.71793 7.73777 10.167 7.73777C11.5061 7.73777 12.5954 8.8271 12.5954 10.1662C12.5954 10.6153 12.465 11.0311 12.2515 11.3923L8.94086 8.08164ZM2.91948 10.1663C3.47364 8.69671 4.48313 7.47453 5.76524 6.62396L7.20082 8.05954C6.77631 8.6548 6.52413 9.38049 6.52413 10.1662C6.52413 12.1749 8.15813 13.8089 10.1668 13.8089C10.9526 13.8089 11.6783 13.5568 12.2743 13.1322L13.4593 14.3172C9.36837 16.1591 4.46712 14.2714 2.91938 10.1662L2.91948 10.1663Z"
                        fill="#212121"
                      />
                    </svg>
                    <svg
                      v-else
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="w-5 h-5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0001 16.6668C3.71892 16.6668 0.962166 10.6496 0.833366 10.4168C0.732584 10.2355 0.732714 9.7647 0.833496 9.58344C0.962402 9.35062 3.71892 3.33344 10.0001 3.33344C16.2813 3.33344 19.038 9.7673 19.1668 10.0001C19.2676 10.1814 19.2676 10.2355 19.1668 10.4168C19.0379 10.6496 16.2813 16.6668 10.0001 16.6668ZM2.08348 10.0001C2.79676 11.0813 5.22192 15.4168 10.0001 15.4168C14.7931 15.4168 17.2058 11.0828 17.9168 9.99996C17.2036 8.91872 14.7783 4.58344 10.0001 4.58344C5.20712 4.58344 2.79448 8.9173 2.08348 10.0001ZM10.0001 13.6C8.01496 13.6 6.40012 11.9851 6.40012 9.99996C6.40012 8.0148 8.01496 6.39996 10.0001 6.39996C11.9853 6.39996 13.6001 8.0148 13.6001 9.99996C13.6001 11.9851 11.9853 13.6 10.0001 13.6ZM10.0001 7.59996C8.67668 7.59996 7.60012 8.67652 7.60012 9.99996C7.60012 11.3234 8.67668 12.4 10.0001 12.4C11.3236 12.4 12.4001 11.3234 12.4001 9.99996C12.4001 8.67652 11.3236 7.59996 10.0001 7.59996Z"
                        fill="#212121"
                      />
                    </svg>
                  </div>
                  <label
                    for="password-confirmation"
                    class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
                    :class="
                      errors.passwordConfirmation
                        ? 'text-[#DE1306]'
                        : 'text-[#212121]'
                    "
                  >
                    Konfirmasi Kata Sandi Baru
                  </label>
                </div>
                <p
                  v-if="errors.passwordConfirmation"
                  class="text-xs text-[#DE1306] font-solusi"
                >
                  {{ message.passwordConfirmation }}
                </p>
              </div>
              <button
                v-if="!resetProcess"
                type="submit"
                class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6"
                :class="
                  checkFormReset
                    ? 'bg-[#00446F] text-white'
                    : 'bg-border text-[#9E9E9E] cursor-not-allowed'
                "
                :disabled="!checkFormReset"
              >
                Reset Password
              </button>
              <button
                v-else
                class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6 bg-border text-[#9E9E9E] cursor-wait"
                disabled
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="spinner"
                  class="w-6 h-6 animate-spin"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#00446F"
                    d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
                  ></path>
                </svg>
              </button>
            </form>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UserModel from '@/models/UserModel'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Reset Password',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      isLoading: true,
      resetForm: {
        token: '' as any,
        password: '',
        passwordConfirmation: '',
      },
      errors: {
        password: false,
        passwordConfirmation: false,
      },
      message: {
        password: '',
        passwordConfirmation: '',
      },
      passwordStatus: false,
      passwordConfirmStatus: false,
      isExpired: false,
      isResetSuccess: false,
      emailRequest: '',
      errorFieldEmail: false,
      errorEmailMessage: '',
      resetProcess: false,
    }
  },
  async created() {
    if (this.$route.query.token) {
      this.resetForm.token = this.$route.query.token
      await this.checkToken()
    } else {
      this.isExpired = true
      this.isLoading = false
    }
  },
  computed: {
    checkFormReset(): boolean {
      if (
        this.resetForm.password != '' &&
        this.resetForm.passwordConfirmation != '' &&
        this.errors.password == false &&
        this.errors.passwordConfirmation == false
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    togglePassword() {
      this.passwordStatus = !this.passwordStatus
    },
    togglePasswordConfirm() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus
    },
    checkPassword() {
      this.errors.password = false
      this.errors.passwordConfirmation = false
      if (this.resetForm.password.length < 8) {
        this.errors.password = true
        this.message.password =
          'Min. 8 karakter terdiri dari angka, huruf, simbol.'
      }
      if (this.resetForm.passwordConfirmation != '') {
        if (this.resetForm.password !== this.resetForm.passwordConfirmation) {
          this.errors.passwordConfirmation = true
          this.message.passwordConfirmation =
            'Konfirmasi kata sandi tidak sama dengan kata sandi.'
        }
      }
      return
    },
    async checkToken() {
      await UserModel.checkResetToken(this.resetForm.token)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            this.isExpired = false
          } else {
            this.isExpired = true
          }
        })
        .catch(err => {
          this.isLoading = false
          this.isExpired = true
        })
    },
    async resetPassword() {
      this.resetProcess = true
      await UserModel.resetPassword(this.resetForm)
        .then(res => {
          if (res.success) {
            this.toast.success('Kata Sandi berhasil diganti, Silahkan login')
            setTimeout(() => {
              this.$router.push('/register')
            }, 1000)
          } else {
            this.resetProcess = false
            this.toast.error('Terjadi kesalahan, Silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          this.resetProcess = false
        })
    },
  },
})
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#scroll-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #9e9e9e;
}
::v-deep(.p-inputtext) {
  padding: 0;
}
</style>
