
import { defineComponent } from 'vue'
import UserModel from '@/models/UserModel'
import { useToast } from 'vue-toastification'

export default defineComponent({
  name: 'Reset Password',
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      isLoading: true,
      resetForm: {
        token: '' as any,
        password: '',
        passwordConfirmation: '',
      },
      errors: {
        password: false,
        passwordConfirmation: false,
      },
      message: {
        password: '',
        passwordConfirmation: '',
      },
      passwordStatus: false,
      passwordConfirmStatus: false,
      isExpired: false,
      isResetSuccess: false,
      emailRequest: '',
      errorFieldEmail: false,
      errorEmailMessage: '',
      resetProcess: false,
    }
  },
  async created() {
    if (this.$route.query.token) {
      this.resetForm.token = this.$route.query.token
      await this.checkToken()
    } else {
      this.isExpired = true
      this.isLoading = false
    }
  },
  computed: {
    checkFormReset(): boolean {
      if (
        this.resetForm.password != '' &&
        this.resetForm.passwordConfirmation != '' &&
        this.errors.password == false &&
        this.errors.passwordConfirmation == false
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    togglePassword() {
      this.passwordStatus = !this.passwordStatus
    },
    togglePasswordConfirm() {
      this.passwordConfirmStatus = !this.passwordConfirmStatus
    },
    checkPassword() {
      this.errors.password = false
      this.errors.passwordConfirmation = false
      if (this.resetForm.password.length < 8) {
        this.errors.password = true
        this.message.password =
          'Min. 8 karakter terdiri dari angka, huruf, simbol.'
      }
      if (this.resetForm.passwordConfirmation != '') {
        if (this.resetForm.password !== this.resetForm.passwordConfirmation) {
          this.errors.passwordConfirmation = true
          this.message.passwordConfirmation =
            'Konfirmasi kata sandi tidak sama dengan kata sandi.'
        }
      }
      return
    },
    async checkToken() {
      await UserModel.checkResetToken(this.resetForm.token)
        .then(res => {
          this.isLoading = false
          if (res.success) {
            this.isExpired = false
          } else {
            this.isExpired = true
          }
        })
        .catch(err => {
          this.isLoading = false
          this.isExpired = true
        })
    },
    async resetPassword() {
      this.resetProcess = true
      await UserModel.resetPassword(this.resetForm)
        .then(res => {
          if (res.success) {
            this.toast.success('Kata Sandi berhasil diganti, Silahkan login')
            setTimeout(() => {
              this.$router.push('/register')
            }, 1000)
          } else {
            this.resetProcess = false
            this.toast.error('Terjadi kesalahan, Silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.toast.error(err.response.data.errors[0].message)
          this.resetProcess = false
        })
    },
  },
})
